import { useLazyQuery, useMutation } from '@apollo/client';
import DailyIframe, { DailyCall } from '@daily-co/daily-js';
import { useDimensions } from 'react-native-web-hooks';
import { useNavigation } from '@react-navigation/native';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Platform, ScrollView, Text, View } from 'react-native';
import { HorizontalDivider } from '../..';
import { palettes } from '../../../config';
import { EDIT_EVENT_STREAM_TIMELINE } from '../../../graphql/events/mutations';
import {
	GET_SINGLE_LIVE_EVENT_DETAILS,
	GET_STREAM_RECORD_LINK,
} from '../../../graphql/events/queries';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
	// getMediaStream,
	stopVideoAndAudio,
} from '../../../screens/LiveEventScreen';
import { TUseNavigation } from '../../../types/exportedTypes';
import { getSingleLoadingState } from '../../../utils/getSingleLoadingState';
import {
	LiveTVIcon,
	LogoutIcon,
	MicOutlineIcon,
	PhoneHandleIcon,
	VolumeIcon,
} from '../../common/icons';
import Button from '../../common/button/Button';
import PlayIcon from '../../common/icons/PlayIcon';
import Loader from '../../common/loader/Loader';
import RatingModal from '../../common/rating-modal/RatingModal';
import Spacing from '../../common/spacing/Spacing';
import { LiveEventProductViewProps } from '../event-section-mobile/ProductView';
import StreamHeader from '../event-section-mobile/StreamHeader';
// import StreamHeaderTab from '../event-section-mobile/StreamHeaderTab';
import AboutEvent, { AboutEventProps } from './AboutEvent';
import { HostDetails } from './HostDetails';
import { LiveEventLiveTab } from './LiveEventLiveTab';
import { LiveEventLock } from './LiveEventLock';
import { LiveEventParticipants } from './LiveEventParticipants';
import { LiveEventProducts } from './LiveEventProducts';
import LiveEventProfileCard, {
	LiveEventProfileCardProps,
} from './LiveEventProfileCard';
import { LiveEventStats } from './LiveEventStats';
import { LiveEventTiles } from './LiveEventTiles';
import { LiveStreamActionWrapper } from './LiveStreamActionWrapper';
import { LoadIVSScript } from './LoadIVSScript';
import {
	liveEventInitialState,
	liveEventReducer,
} from './utils/liveEventSection.state';
import {
	LiveEventSectionStyles,
	stylesLiveStreamContainer,
} from './utils/LiveEventSection.styles';
import {
	getDailyCallItems,
	getLiveEventSectionTabs,
	getProfileCardDetails,
	getRatingEntity,
	getRtmpUrl,
	initializeIVSPlayerEvents,
	initializeLiveStreamEvents,
	isAccountHostOrSeller,
	isAudioEnabledForHostSeller,
	isVideoEnabledForHostSeller,
	LOCK_DIMENSIONS,
} from './utils/LiveEventSection.utils';
import { LiveEventMessage } from '../product-section/event-chat/EventChatBox';
import { ADD_TO_EVENT_ATTENDED } from '../../../graphql/eventAttendance/mutation';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
// import { socket } from '../../../../App';
import { db } from '../../../services/firebase';
import {
	addDoc,
	collection,
	onSnapshot,
	query,
	queryEqual,
	Timestamp,
} from 'firebase/firestore';
import { isOneLessMinuteDifference } from '../../../utils/addHoursToTime';
// import { setTypeOfAccount } from '../../../redux/createUserSlice';
// import {
// 	setPrimaryColors,
// 	setSignupSidebarValues,
// } from '../../../redux/uiSlice';
import { makeEven } from '../../../utils/chatUtil';
import DesktopHighLightProduct from './DesktopHighLightProduct';
// import { EventSelectedProduct } from '../product-section/event-chat/People';
// import { shortenName } from '../../../utils/manipulateString';
import Animated, {
	useAnimatedStyle,
	useSharedValue,
	withSpring,
} from 'react-native-reanimated';
import ProductHighLightMarker from './ProductHighLightMarker';
import EventNavigation from './EventNavigation';
import axios from 'axios';
import BackEditIcon from '../../common/icons/BackEditIcon';
import AnonymousChatModal from '../../common/anonymous-chat/Anonymous';
import DeleteModal from '../../common/delete-modal/DeleteModal';
import { FACEBOOK_CONFIG_ID } from '../../../constants/KEYS';

type Props = {
	showLocks: boolean;
	liveStream: TLiveStream;
	messages: LiveEventMessage[];
	eventDetails: TSingleLiveEventData;
	hostDetails: TLiveEventAffiliatedHosts;
	highlightedEventProductsTime: number[];
	setLiveStreamAccessToken: (e: string) => void;
	onTimeUpdate?: (e?: TODO) => void;
	onMessageSend?: (e?: TODO) => void;
	handleChange?: (e?: string) => void;
	handleTypingFocusIn?: () => void;
	liveEventId?: string | undefined;
} & AboutEventProps &
	LiveEventProfileCardProps &
	LiveEventProductViewProps;

const LiveEventSection = ({
	about,
	userId,
	products,
	username,
	messages,
	showLocks,
	liveStream,
	eventDetails,
	userDescription,
	userProfileImageLink,
	liveEventId,
	highlightedEventProductsTime,
	onTimeUpdate,
	handleChange,
	setLiveStreamAccessToken,
}: Props) => {
	const { t } = useTranslation();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const { setAlert } = useSetAlert();
	const navigation = useNavigation<TUseNavigation>();
	// const { window } = useDimensions();
	const [videoHeight, setVideoHeight] = useState('');
	const [isHostOrSellerInEvent, setIsHostOrSellerInEvent] = useState(false);
	const [isHostLeaveEvent, setHostLeaveEvent] = useState(false);
	const [isLoadingRecording, setIsLoadingRecording] = useState(false);
	const [hidePlayRecording, setHidePlayRecording] = useState(false);
	const [recordingLink, setRecordingLink] = useState('');
	const [currentVideoTime, setCurrentVideoTime] = useState(0);
	const [isStreamDeviceDesktop, setIsStreamDeviceDesktop] = useState(
		eventDetails?.deviceMetadata?.deviceType === 'Desktop'
	);
	const [deviceType, setDeviceType] = useState(
		isLessThanDesktopBase ? 'Mobile' : 'Desktop'
	);
	const [timeLeft, setTimeLeft] = useState<any>({});
	const [liveStreamLink, setLiveStreamLink] = useState(
		liveStream?.stream?.channel?.playbackUrl
	);
	const [leftCall, setLeftCall] = useState(false);

	const [totalVideoTime, setTotalVideoTime] = useState(0);

	const consumerVideoEl = useRef<any>(null);

	const streamRecordingInstanceRef = useRef<any>(uuidv4());

	const getTabs = (tabType: 'section' | 'participant') =>
		getLiveEventSectionTabs(t, tabType);
	const { isUserLoggedIn } = useAppSelector(state => state.auth);

	const typeOfAccount: any = useAppSelector(
		state => state.auth.loggedInUserDetails?.typeOfAccount
	);
	const eventProduct = useAppSelector(state => state.eventProduct);

	const disableDailyRoomNStream = useAppSelector(
		state => state.getEvent.disableDailyRoomNStream
	);

	const loggedInUserDetails = useAppSelector(
		state => state.auth.loggedInUserDetails
	);

	const liveEventAudience = useAppSelector(
		state => state.chat.liveEventAudience
	);

	const [state, dispatch] = useReducer(
		liveEventReducer,
		liveEventInitialState
	);

	const [getCloudRecording] = useLazyQuery(GET_STREAM_RECORD_LINK, {
		onCompleted: data => {
			console.log({ cloudRecordData: data });
			setRecordingLink(data?.getCloudRecording?.data?.download_link);
		},
	});

	const {
		callItems,
		dailyJsState,
		loadingDaily,
		dailyJsObject,
		loadingCamera,
		recordingId,
		isLiveStreaming,
		loadingPlayerIvs,
		ivsPlayerInstance,
		selectedSectionTab,
		playerScriptLoaded,
		isShowingRatingModal,
		loadingLiveStreaming,
		selectedParticipantTab,
		isHostOrSellerInMeeting,
		isLiveStreamForConsumerIdle,
		isVolumeDisabledForConsumer,
		isHostOrSellerActiveForConsumer,
	} = state;

	useEffect(() => {
		dispatch({
			type: 'INITIAL_LOAD_IVS_PLAYER_LOADING',
			payload: {
				typeOfAccount,
				eventStreamTimeline: eventDetails?.eventStreamTimeline,
			},
		});
		return () => {
			cleanUpBeforeUnmount();
		};
	}, []);

	const isStreamEnded = eventDetails?.eventStreamTimeline?.endedAt;

	useEffect(() => {
		if (
			eventDetails?.deviceMetadata?.streamRecordingInstance &&
			!!isStreamEnded?.length
		) {
			getCloudRecording({
				variables: {
					_id: eventDetails?.deviceMetadata?.streamRecordingInstance,
				},
			});
		}
	}, [eventDetails?.deviceMetadata?.streamRecordingInstance]);

	useEffect(() => {
		if (!consumerVideoEl?.current) {
			return;
		}

		// console.log({ consumerVideoEl });

		const video = consumerVideoEl?.current;

		const handleLoadedMetadata = () => {
			console.log('Metadata is loading...');
		};

		const handleCanPlayThrough = () => {
			console.log('Video is ready to be played!');
			consumerVideoEl?.current?.play();
			setPlayVideo(true);
			setIsLoadingRecording(false);
			setHidePlayRecording(true);
			// consumerVideoEl?.current?.play();
			// setPlayVideo(true);
			// setIsLoadingRecording(false);
			// setHidePlayRecording(true);
		};

		video.onloadedmetadata = handleLoadedMetadata;
		video.oncanplaythrough = handleCanPlayThrough;

		// Cleanup: Reset event handlers when the component unmounts
		return () => {
			video.onloadedmetadata = null;
			video.oncanplaythrough = null;
		};
	}, [recordingLink]);

	const [setEventStreamTimeline] = useMutation<
		TEditEventStreamTimelineResponse,
		TEditEventStreamTimelineInputs
	>(EDIT_EVENT_STREAM_TIMELINE, {
		refetchQueries: [
			{
				query: GET_SINGLE_LIVE_EVENT_DETAILS,
				variables: {
					_id: eventDetails._id,
					typeOfAccount,
				},
			},
		],
	});

	useEffect(() => {
		if (!dailyJsObject || typeOfAccount === 'CONSUMER') return;

		const events: any = [
			'participant-joined',
			'participant-updated',
			'participant-left',
		];

		function handleNewParticipantsState(callObject: DailyCall) {
			const _callItems = getDailyCallItems(callObject.participants());

			dispatch({
				type: 'SET_CALL_ITEM',
				payload: _callItems,
			});
		}

		initializeLiveStreamEvents({
			t,
			dailyJsObject,
			cleanUpBeforeUnmount,
			setAlert,
			startLeavingCall,
			dispatch,
		});

		handleNewParticipantsState(dailyJsObject);

		for (const event of events) {
			dailyJsObject.on(event, () =>
				handleNewParticipantsState(dailyJsObject)
			);
		}

		return function cleanup() {
			for (const event of events) {
				dailyJsObject.off(event, () =>
					handleNewParticipantsState(dailyJsObject)
				);
			}
		};
	}, [dailyJsObject]);

	useEffect(() => {
		//https://aws.github.io/amazon-ivs-player-docs/1.8.0/web/
		let player: any | undefined;

		if (playerScriptLoaded) {
			console.log({ liveStream });

			//@ts-ignore
			player = IVSPlayer?.create();
			dispatch({
				type: 'SET_IVS_PLAYER',
				payload: player,
			});
			player.attachHTMLVideoElement(
				document.getElementById('video-player')
			);
			const referer = document?.referrer;
			const video: any = document.getElementById('video-player');
			video.muted = true;
			video.play();
			video.onplaying = function () {
				video.muted = false;
			};
			if (
				(/iPad|iPhone|iPod/.test(navigator.userAgent) &&
					!window.MSStream) ||
				!!referer?.length
			) {
				video.muted = true;
				video.play();
				video.onplaying = function () {
					video.muted = false;
				};
			}
			player.load(
				liveStream?.stream?.channel?.playbackUrl || liveStreamLink
			);
			player.play();
			initializeIVSPlayerEvents({
				t,
				player,
				eventDetails,
				navigation,
				setAlert,
				dispatch,
				isStreamEnded,
			});
		}

		return () => {
			if (playerScriptLoaded) {
				player && player.delete();
			}
		};
	}, [playerScriptLoaded, isHostOrSellerInEvent, liveStreamLink]);

	// useEffect(() => {
	// 	const isHostOrSellerInEvent = liveEventAudience.find(
	// 		audience =>
	// 			audience?.userId === eventDetails?.affiliatedSeller?._id ||
	// 			audience?.userId === eventDetails?.affiliatedHosts?.[0]?._id
	// 	);
	// 	if (isHostOrSellerInEvent) {
	// 		setIsHostOrSellerInEvent(true);
	// 	}
	// }, [liveEventAudience]);

	// Empty git commit
	/******************** START - Functions ***********************/

	const startJoiningCall = async (url: string) => {
		const newCallObject = DailyIframe.createCallObject();
		dispatch({
			type: 'JOIN_CALL',
			payload: newCallObject,
		});
		newCallObject
			.join({
				url,
				token: liveStream.token,
			})
			.then(result => {})
			.catch(error => {});
	};

	const startLeavingCall = () => {
		if (!dailyJsObject) return;

		if (dailyJsState === 'STATE_ERROR') {
			dailyJsObject.destroy().then(() => {
				dispatch({
					type: 'LEAVE_CALL_ERROR',
				});
			});
		} else {
			dispatch({
				type: 'LEAVE_CALL',
			});
			dailyJsObject.leave();
		}

		dailyJsObject.stopLiveStreaming();
		if (isLiveStreaming) {
			setEventStreamTimeline({
				variables: {
					_id: eventDetails._id,
					endedAt: dayjs().toDate(),
					deviceType,
					os: Platform.OS,
				},
			});
			if (
				typeOfAccount === 'SELLER' &&
				!eventDetails?.affiliatedHosts?.length
			) {
				return;
			}
			dispatch({
				type: 'TOGGLE_RATING_MODAL',
			});
		}
	};

	const onPressMicSellerOrHost = () => {
		if (!isHostOrSellerInMeeting) {
			return;
		}
		dailyJsObject?.setLocalAudio(
			!isAudioEnabledForHostSeller(dailyJsObject)
		);
	};

	const onPressVideoSellerOrHost = () => {
		if (!isHostOrSellerInMeeting) {
			return;
		}
		dailyJsObject?.setLocalVideo(
			!isVideoEnabledForHostSeller(dailyJsObject)
		);
	};

	const startLiveStream = async () => {
		if (!dailyJsObject) return;

		const rtmpUrl = getRtmpUrl(liveStream);

		// console.log({ rtmpUrl });

		dispatch({
			type: 'SET_LOADING_LIVE_STREAMING',
			payload: true,
		});

		if (isLiveStreaming) {
			await setEventStreamTimeline({
				variables: {
					_id: eventDetails._id,
					endedAt: dayjs().toDate(),
					deviceType,
					os: Platform.OS,
				},
			});
			dailyJsObject.stopLiveStreaming();
			// const stream = await getMediaStream();
			// stopVideoAndAudio(stream);
			// startLeavingCall();
		} else {
			if (!eventDetails?.eventStreamTimeline?.startedAt) {
				await setEventStreamTimeline({
					variables: {
						_id: eventDetails._id,
						startedAt: dayjs().toDate(),
						deviceType,
						os: Platform.OS,
					},
				});
			}

			dailyJsObject.startLiveStreaming({
				// rtmpUrl,
				endpoints: [
					{ endpoint: 'rtmp_ivs' },
					{ endpoint: 'facebook_stream' },
					{ endpoint: 'rtmp_youtube' },
				],
			});

			await addEventAttendance({
				variables: {
					user: loggedInUserDetails?._id,
					event: liveEventId,
				},
			});
		}
	};

	const onPressVolumeConsumer = () => {
		//https://aws.github.io/amazon-ivs-player-docs/1.8.0/web/interfaces/player.html#setvolume
		//https://aws.github.io/amazon-ivs-player-docs/1.8.0/web/interfaces/player.html#getvolume
		const video: any = document.getElementById('video-player');
		if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
			if (video.muted === false) {
				video.muted = true;
				dispatch({
					type: 'IVS_PLAYER_VOLUME_CHANGE',
					payload: 0,
				});
			} else {
				video.muted = false;
				dispatch({
					type: 'IVS_PLAYER_VOLUME_CHANGE',
					payload: 1,
				});
			}
		}
		if (!ivsPlayerInstance) return;
		ivsPlayerInstance.setVolume(isVolumeDisabledForConsumer ? 1 : 0);
	};

	const onPressHangBtnConsumer = () => {
		ivsPlayerInstance && ivsPlayerInstance.pause();
		// const stream = await getMediaStream();
		// stopVideoAndAudio(stream);
		dispatch({
			type: 'TOGGLE_RATING_MODAL',
		});
	};

	const handleOnPressLiveStreamButton = () => {
		// if (
		// disableDailyRoomNStream
		// || (!!eventDetails?.eventStreamTimeline &&
		// 	!!eventDetails?.eventStreamTimeline?.endedAt)
		// ) {
		// setAlert(t('Event cannot be live-streamed again'), 'danger');
		// return;
		// }

		if (!isHostOrSellerInMeeting) {
			return;
		}

		// const isCurrentTimeGreaterThanExpiry = currentTimeGreaterThanExpiry(
		// 	eventDetails.startingEventDateTime
		// );

		if (eventDetails?.socialStreamKey?.facebookStreamKey?.id) {
			window?.FB?.login(
				(response: any) => {
					console.log({
						facebookResponse: response,
					});

					setLiveStreamAccessToken(
						response?.authResponse?.accessToken
					);
					// facebookCallback(
					// 	response?.authResponse
					// );
				},
				{
					config_id: FACEBOOK_CONFIG_ID,
					// response_type: 'code',
					// override_default_response_type: true
				}
			);
		}

		// if (!isCurrentTimeGreaterThanExpiry) {
		startLiveStream().then(async () => {
			setTimeout(() => {
				// socket.emit('startLiveStream', {
				// 	roomId: eventDetails._id,
				// 	liveStream: liveStream?.stream?.channel?.playbackUrl,
				// });
				addDoc(
					collection(
						db,
						'startLiveStream',
						eventDetails?._id,
						'StartLiveStreamTable'
					),
					{
						roomId: eventDetails._id,
						liveStream: liveStream?.stream?.channel?.playbackUrl,
						createdAt: new Date().toLocaleString('en-US'),
					}
				);
			}, 15000);
			if (!liveStream?.p2p?.url) return;
			console.log({
				width: makeEven(+Dimensions.get('window').width * 0.7),
				height: makeEven(+videoHeight),
				recordingLink,
				streamInstance:
					eventDetails?.deviceMetadata?.streamRecordingInstance,
				instance: streamRecordingInstanceRef?.current,
			});

			try {
				if (
					recordingLink &&
					eventDetails?.deviceMetadata?.streamRecordingInstance
				) {
					await dailyJsObject?.updateRecording({
						instanceId:
							eventDetails?.deviceMetadata
								?.streamRecordingInstance, // optional: default value
						width: isLessThanDesktopBase
							? makeEven(+Dimensions.get('window').width)
							: makeEven(+Dimensions.get('window').width * 0.63),
						height: isLessThanDesktopBase
							? makeEven(+Dimensions.get('window').height)
							: makeEven(+videoHeight),
						fps: 25,
						layout: {
							preset: 'default',
							max_cam_streams: 5,
						},
					});
				} else {
					await dailyJsObject?.startRecording({
						instanceId: streamRecordingInstanceRef?.current, // optional: default value
						width: isLessThanDesktopBase
							? makeEven(+Dimensions.get('window').width)
							: makeEven(+Dimensions.get('window').width * 0.63),
						height: isLessThanDesktopBase
							? makeEven(+Dimensions.get('window').height)
							: makeEven(+videoHeight * 0.97),
						fps: 25,
						// videoBitrate: 1000,
						layout: {
							preset: 'default',
							max_cam_streams: 5,
						},
					});
				}
			} catch (error) {
				console.log({ error });
			}
		});

		// } else {
		// setAlert(t('Event expired') + '!', 'danger');
		// }
	};

	useEffect(() => {
		const startLiveStreamDocRef = collection(
			db,
			'startLiveStream',
			eventDetails?._id,
			'StartLiveStreamTable'
		);
		const startLiveStreamQuery = query(startLiveStreamDocRef);

		onSnapshot(startLiveStreamQuery, querySnapshot => {
			querySnapshot.forEach(doc => {
				const isTimeOfDocOneWithinOneMinute = isOneLessMinuteDifference(
					new Date(doc?.data()?.createdAt),
					new Date()
				);
				if (
					doc?.data()?.roomId === eventDetails._id &&
					isTimeOfDocOneWithinOneMinute
				) {
					setIsHostOrSellerInEvent(true);
					setLiveStreamLink(doc?.data()?.liveStream);
				}
			});
		});

		// socket.on('startLiveStream', ({ startLiveStream, liveStream }: any) => {
		// 	setIsHostOrSellerInEvent(startLiveStream);
		// 	if (liveStream) setLiveStreamLink(liveStream);
		// });

		const docRef = collection(
			db,
			'endStream',
			eventDetails._id,
			'LeaveStream'
		);
		const q = query(docRef);

		onSnapshot(q, querySnapshot => {
			querySnapshot.forEach(doc => {
				const isTimeOfDocOneWithinOneMinute = isOneLessMinuteDifference(
					new Date(doc?.data()?.createdAt),
					new Date()
				);
				if (isTimeOfDocOneWithinOneMinute) {
					const isUserInvited = eventDetails?.invitedPeople
						?.map((data: any) => data._id)
						?.includes(loggedInUserDetails?._id);
					if (isUserInvited) setHostLeaveEvent(true);
				}
			});
		});
	}, []);

	const onPressPlayButtonForConsumer = () => {
		// if (isEventDone(eventDetails)) {
		// setAlert(t('Live-stream has ended!'), 'danger');
		// return;
		// }

		ivsPlayerInstance && ivsPlayerInstance.play();

		dispatch({
			type: 'SET_IS_LIVESTREAM_IDLE_FOR_CONSUMER',
			payload: false,
		});
	};

	useEffect(() => {
		if (!eventDetails?.startingEventDateTime) return;

		const timer = setInterval(() => {
			setTimeLeft(
				calculateTimeLeft(+eventDetails?.startingEventDateTime)
			);
		}, 1000);

		if (timeLeft === null) clearInterval(timer);

		return () => clearInterval(timer); // Cleanup on component unmount
	}, [eventDetails?.startingEventDateTime]);

	const calculateTimeLeft = (eventTime: number) => {
		const currentTime = Date.now();
		const difference = eventTime - currentTime;

		if (difference <= 0) {
			return null; // Event has already started
		}

		const seconds = String(Math.floor((difference / 1000) % 60)).padStart(
			2,
			'0'
		);
		const minutes = String(
			Math.floor((difference / (1000 * 60)) % 60)
		).padStart(2, '0');
		const hours = String(
			Math.floor((difference / (1000 * 60 * 60)) % 24)
		).padStart(2, '0');
		const days = String(
			Math.floor(difference / (1000 * 60 * 60 * 24))
		).padStart(2, '0');

		return { days, hours, minutes, seconds };
	};

	useEffect(() => {
		if (
			typeOfAccount === 'CONSUMER' &&
			!isLiveStreamForConsumerIdle &&
			isHostOrSellerActiveForConsumer
		) {
			addEventAttendance({
				variables: {
					user: loggedInUserDetails?._id,
					event: liveEventId,
				},
			});
		}
	}, [isLiveStreamForConsumerIdle, isHostOrSellerActiveForConsumer]);
	const onLoadIVSPlayer = () => {
		dispatch({
			type: 'ON_LOAD_IVS_PLAYER',
		});
	};

	const joinRoom = () => {
		// if (
		// (!!eventDetails?.eventStreamTimeline &&
		// !!eventDetails?.eventStreamTimeline?.endedAt) ||
		// disableDailyRoomNStream
		// ) {
		// setAlert(t('Event cannot be live-streamed again'), 'danger');
		// return;
		// }

		if (!liveStream?.p2p?.url) return;
		else {
			dispatch({
				type: 'SET_LOADING_BOOLEANS_ON_JOINING_ROOM',
			});

			isAccountHostOrSeller(typeOfAccount) &&
				startJoiningCall(liveStream?.p2p?.url);
		}
	};
	const [playVideo, setPlayVideo] = useState(false);

	const handlePlay = () => {
		if (!playVideo) {
			consumerVideoEl.current.play();
			setPlayVideo(true);
		} else {
			consumerVideoEl.current.pause();
			setPlayVideo(false);
		}
	};

	const handleRecord = async () => {
		setIsLoadingRecording(true);
		if (typeOfAccount === 'CONSUMER' || !typeOfAccount) handlePlay();
	};

	const handlePauseAndPlay = () => {
		console.log('clicked');

		handlePlay();
	};

	useEffect(() => {
		if (liveStream?.p2p?.url && recordingId?.length) {
			setEventStreamTimeline({
				variables: {
					_id: eventDetails._id,
					// startedAt: dayjs().toDate(),
					deviceType,
					streamRecordingInstance: recordingId,
					os: Platform.OS,
				},
			}).then(result => {
				console.log('set stream instance');
			});
		}
	}, [recordingId]);

	const onPress = (tab: string) => {
		dispatch({
			type: 'SELECT_SECTION_TAB',
			payload: tab,
		});
	};

	const handleParticipantsOptions = (tab: string) => {
		dispatch({
			type: 'SELECT_PARTICIPANT_TAB',
			payload: tab,
		});
	};

	const cleanUpBeforeUnmount = async () => {
		// const stream = await getMediaStream();
		// stopVideoAndAudio(stream);
		dailyJsObject?.destroy();
		dailyJsObject?.setLocalVideo(false);
		dailyJsObject?.setLocalAudio(false);
		startLeavingCall();
	};

	const onPressLeaveRoomForSellerAndHostButton = async () => {
		dispatch({
			type: 'LEAVE_CALL',
		});
		if (!isHostOrSellerInMeeting) {
			return;
		}
		await addDoc(
			collection(db, 'endStream', eventDetails?._id, 'LeaveStream'),
			{
				hostLeaveStream: true,
				createdAt: new Date().toLocaleString('en-US'),
			}
		);
		// const stream = await getMediaStream();
		// stopVideoAndAudio(stream);
		startLeavingCall();
		setLeftCall(true);
		navigation.navigate('Events');
	};

	/******************** END  -  Functions ***********************/

	/******************** START - Extracted JSX ***********************/

	const [showEndCallModal, setShowEndCallModal] = useState(false);

	const SellerAndHostActionButtons = () => {
		return (
			<Spacing>
				<LiveStreamActionWrapper
					icon={<MicOutlineIcon />}
					isBgRed={!isAudioEnabledForHostSeller(dailyJsObject)}
					onPress={onPressMicSellerOrHost}
				/>
				<LiveStreamActionWrapper
					icon={<LiveTVIcon />}
					// isBgRed={!isLiveStreaming}
					isBgRed={!isVideoEnabledForHostSeller(dailyJsObject)}
					onPress={onPressVideoSellerOrHost}
					// onPress={handleOnPressLiveStreamButton}
					// loading={loadingLiveStreaming}
				/>
				{isLiveStreaming ? (
					<LiveStreamActionWrapper
						isBgRed={true}
						onPress={() => setShowEndCallModal(true)}
						icon={
							<PhoneHandleIcon
								style={{
									marginTop: 4,
									marginRight: 3,
								}}
							/>
						}
					/>
				) : (
					<></>
				)}
			</Spacing>
		);
	};

	const ConsumerActionButtons = () => {
		return (
			<Spacing>
				<LiveStreamActionWrapper
					icon={<VolumeIcon />}
					onPress={onPressVolumeConsumer}
					isBgRed={isVolumeDisabledForConsumer}
				/>
				<LiveStreamActionWrapper
					icon={<LogoutIcon />}
					isBgRed={true}
					onPress={async () => {
						ivsPlayerInstance && ivsPlayerInstance.pause();
						// const stream = await getMediaStream();
						// stopVideoAndAudio(stream);
						dispatch({
							type: 'TOGGLE_RATING_MODAL',
						});
					}}
				/>
			</Spacing>
		);
	};

	/******************** END  -  Extracted JSX ***********************/

	/******************** START - Conditional Constants ***********************/

	const showPlayButtonForHostOrSeller =
		isAccountHostOrSeller(typeOfAccount) && !isHostOrSellerInMeeting;

	const showPlayButtonForConsumer =
		typeOfAccount === 'CONSUMER' &&
		isLiveStreamForConsumerIdle &&
		!loadingPlayerIvs;

	const playButton = {
		isVisible: showPlayButtonForConsumer || showPlayButtonForHostOrSeller,
		onPress:
			typeOfAccount === 'CONSUMER'
				? onPressPlayButtonForConsumer
				: joinRoom,
		loading:
			isAccountHostOrSeller(typeOfAccount) &&
			getSingleLoadingState(loadingCamera, loadingDaily),
	};

	useEffect(() => {
		if (
			typeOfAccount === 'CONSUMER' &&
			!liveStream?.stream?.channel?.playbackUrl &&
			!isStreamEnded
		) {
			setAlert(t('Live stream not available!'), 'danger');
		}
		playButton.onPress();
	}, []);

	const isLoadingCameraOrDailyOrIvs = useMemo(
		() =>
			getSingleLoadingState(
				loadingCamera,
				loadingDaily,
				loadingPlayerIvs
			),
		[loadingCamera, loadingDaily, loadingPlayerIvs]
	);

	const showLiveStreamProfileCardForHostSeller =
		isAccountHostOrSeller(typeOfAccount) &&
		isHostOrSellerInMeeting &&
		!isLoadingCameraOrDailyOrIvs;

	const showLiveStreamProfileCardForConsumer =
		typeOfAccount === 'CONSUMER' &&
		!isLoadingCameraOrDailyOrIvs &&
		!isLiveStreamForConsumerIdle;

	const showLiveStreamProfileCard =
		showLiveStreamProfileCardForHostSeller ||
		showLiveStreamProfileCardForConsumer;

	const showActionButtonsForConsumer =
		typeOfAccount === 'CONSUMER' && !isLiveStreamForConsumerIdle;

	const showActionButtonsForHostOrSeller =
		isAccountHostOrSeller(typeOfAccount) && isHostOrSellerInMeeting;

	const showLiveEventStatsForHostOrSeller = isLiveStreaming;
	const showLiveEventStatsForConsumer =
		typeOfAccount === 'CONSUMER' && !isLiveStreamForConsumerIdle;

	const showLiveEventStats =
		showLiveEventStatsForHostOrSeller || showLiveEventStatsForConsumer;

	/******************** END  -  Conditional Constants ***********************/

	const profileCardDetails = getProfileCardDetails(
		eventDetails,
		typeOfAccount,
		loggedInUserDetails
	);

	const [addEventAttendance] = useMutation(ADD_TO_EVENT_ATTENDED);

	useEffect(() => {
		if (
			showLiveEventStatsForConsumer ||
			showLiveEventStatsForHostOrSeller
		) {
		}
	}, [showLiveEventStatsForHostOrSeller, showLiveEventStatsForConsumer]);

	const PlayButton = () =>
		playButton.isVisible ? (
			<View style={LiveEventSectionStyles.playButtonContainer}>
				<LiveStreamActionWrapper
					icon={
						<PlayIcon
							width={20}
							height={20}
							color={'white'}
							containerStyles={{
								margin: 'auto',
							}}
							onPress={playButton.onPress}
						/>
					}
					onPress={playButton.onPress}
					loading={playButton.loading}
				/>
			</View>
		) : (
			<></>
		);

	const playRecordingFunc = () => {
		consumerVideoEl?.current?.play();
		setPlayVideo(true);
		setIsLoadingRecording(false);
		setHidePlayRecording(true);
	};

	const RecordingPlayButton = () => (
		<View style={LiveEventSectionStyles.playButtonContainer}>
			<LiveStreamActionWrapper
				icon={
					<PlayIcon
						width={20}
						height={20}
						color={'white'}
						containerStyles={{
							margin: 'auto',
						}}
						onPress={handlePauseAndPlay}
					/>
				}
				onPress={handlePauseAndPlay}
				// loading={playButton.loading}
			/>
		</View>
	);

	const handleStartLiveStreamIdle = () => {
		// setAlert(t('Unable to start live event!'), 'danger');
		navigation.navigate('Events');
	};

	// const handleSubToEvent = () => {
	// 	if (isUserLoggedIn) {
	// 		return;
	// 	} else {
	// 		dispatch(setTypeOfAccount('CONSUMER'));
	// 		dispatch(setPrimaryColors('CONSUMER'));
	// 		dispatch(setSignupSidebarValues('CONSUMER'));
	// 		navigation.navigate('PersonalInfo');
	// 	}
	// };

	const showPlayRecordingButton =
		!!isStreamEnded?.length &&
		!hidePlayRecording &&
		(typeOfAccount === 'CONSUMER' || !typeOfAccount);

	const StartLiveStreamButton = ({ size }: { size: string }) => {
		return !isLiveStreaming &&
			!isLoadingCameraOrDailyOrIvs &&
			isAccountHostOrSeller(typeOfAccount) ? (
			<View
				style={[
					LiveEventSectionStyles.startLiveStreamContainer,
					isLessThanDesktopBase && { left: '36%' },
				]}
			>
				{/* {showPlayRecordingButton ? (
					<Button
						size={size as any}
						title={`${t('Play Recording')}`}
						variant="primary"
						loading={isLoadingRecording}
						onPress={handleRecord}
					/>
				) : ( */}
				<Button
					size={size as any}
					title={leftCall ? 'Leave Event' : 'Start Live Stream'}
					variant="primary"
					onPress={
						leftCall
							? handleStartLiveStreamIdle
							: handleOnPressLiveStreamButton
					}
					loading={loadingLiveStreaming}
				/>
				{/* )} */}
			</View>
		) : (
			<></>
		);
	};

	const offset = useSharedValue(0);

	const defaultSpringStyles = useAnimatedStyle(() => {
		return {
			transform: [{ translateY: withSpring(offset.value * 655) }],
		};
	});

	useEffect(() => {
		if (eventProduct?.product?._id) {
			offset.value = offset.value + -0.05;
		} else {
			offset.value = 0;
		}
	}, [eventProduct]);

	const sleekToTime = (time: number) => {
		if (consumerVideoEl?.current && time > 1) {
			consumerVideoEl.current.currentTime = time;
		}
	};

	const handleLoadedMetadata = () => {
		if (consumerVideoEl?.current) {
			setTotalVideoTime(consumerVideoEl.current.duration);
		}
	};

	// useEffect(() => {
	// 	const video = consumerVideoEl.current;

	// 	if (!recordingLink) return

	// 	const handleTimeUpdate = () => {
	// 		const currentTime = video.currentTime;
	// 		const bufferedEnd = video.buffered.end(0);

	// 		// Adjust this value based on your requirements
	// 		const bufferThreshold = 30;

	// 		if (bufferedEnd - currentTime < bufferThreshold) {
	// 			// Request the next range of bytes
	// 			const rangeStart = bufferedEnd;
	// 			const rangeEnd = Math.min(rangeStart + 10 * 1024 * 1024, video.duration);

	// 			axios.get(recordingLink, {
	// 				headers: { Range: `bytes=${rangeStart}-${rangeEnd}` },
	// 				responseType: 'blob',
	// 			})
	// 				.then(response => {
	// 					const newSource = URL.createObjectURL(response.data);
	// 					video.src = newSource;
	// 				})
	// 				.catch(error => console.error('Error fetching video:', error));
	// 		}
	// 	};

	// 	video.addEventListener('timeupdate', handleTimeUpdate);

	// 	return () => {
	// 		video.removeEventListener('timeupdate', handleTimeUpdate);
	// 	};
	// }, [recordingLink]);

	const loadingBeforeEvent =
		!isHostOrSellerActiveForConsumer &&
		typeOfAccount !== 'SELLER' &&
		!recordingLink?.length &&
		!isLiveStreamForConsumerIdle;

	return (
		<View
			style={[
				LiveEventSectionStyles.container,
				isLessThanDesktopBase && LiveEventSectionStyles.mobileContainer,
			]}
		>
			<LoadIVSScript onLoadIVSPlayer={onLoadIVSPlayer} />

			{showEndCallModal && (
				<View style={{ position: 'absolute' }}>
					<DeleteModal
						isOpen={showEndCallModal}
						isHideCheck
						deleteText={t('End')}
						header={t('Are you sure you want to end this Live?')}
						info={t(
							'If you end this Live, the recording will be saved and you will not be able to restart again.'
						)}
						onConfirmDelete={onPressLeaveRoomForSellerAndHostButton}
						onCancelDelete={() => setShowEndCallModal(false)}
					/>
				</View>
			)}

			{(isShowingRatingModal || isHostLeaveEvent) && (
				<RatingModal
					onClose={() => {
						dispatch({
							type: 'TOGGLE_RATING_MODAL',
						});
						// socket.emit('leaveEvent', {
						// 	userId: loggedInUserDetails?._id,
						// });
					}}
					entities={getRatingEntity(eventDetails, typeOfAccount)}
					ratingType={'USER'}
					// openState={true}
					openState={isShowingRatingModal || isHostLeaveEvent}
				/>
			)}
			<ScrollView
				style={LiveEventSectionStyles.scrollContainer}
				showsHorizontalScrollIndicator={false}
			>
				<View
					style={[
						stylesLiveStreamContainer(
							videoHeight,
							consumerVideoEl?.current?.offsetHeight,
							typeOfAccount
						),
						{ position: 'relative' },
						showLocks && { zIndex: '9999999999999' },
					]}
				>
					{eventProduct?.product?._id && !isLessThanDesktopBase ? (
						<Animated.View
							style={[
								defaultSpringStyles,
								LiveEventSectionStyles.desktopHighlightContainer,
							]}
						>
							<DesktopHighLightProduct
								eventProduct={eventProduct?.product}
								eventData={eventDetails}
							/>
						</Animated.View>
					) : null}
					{totalVideoTime && !isLessThanDesktopBase ? (
						<View
							style={[
								LiveEventSectionStyles.highlightedProductMarkerContainer,
								isLessThanDesktopBase && { width: '92%' },
							]}
						>
							<ProductHighLightMarker
								totalTime={totalVideoTime}
								highlightedEventProductsTime={
									highlightedEventProductsTime
								}
								onPressHighlight={sleekToTime}
							/>
						</View>
					) : null}
					<LiveEventLock
						showLocks={showLocks}
						isOnLiveEventPlayer
						hasText
						lockIconDimensions={LOCK_DIMENSIONS.livestreamArea.icon}
						lockBackgroundDimension={
							LOCK_DIMENSIONS.livestreamArea.background
						}
						item={eventDetails}
					/>

					{!isLessThanDesktopBase && (
						<StartLiveStreamButton size="md" />
					)}

					{loadingBeforeEvent && (
						<View
							style={LiveEventSectionStyles.tilesLoaderContainer}
						>
							{timeLeft !== null && (
								<Text style={{ color: '#fff' }}>
									Le Live Commence dans: {timeLeft?.hours}:
									{timeLeft?.minutes}:{timeLeft?.seconds}
								</Text>
							)}
							<Loader color={palettes.light[0]} />
						</View>
					)}

					{playerScriptLoaded &&
						(typeOfAccount === 'CONSUMER' || !typeOfAccount) && (
							<>
								{recordingLink && isStreamEnded ? (
									<video
										playsInline
										muted={false}
										controls={!isLessThanDesktopBase}
										onTimeUpdate={event => {
											setCurrentVideoTime(
												+event?.target?.currentTime
											);
											onTimeUpdate(event);
										}}
										onLoadedMetadata={handleLoadedMetadata}
										src={recordingLink}
										ref={consumerVideoEl}
										style={{
											width: '100%',
											height:
												!isStreamDeviceDesktop &&
												!isLessThanDesktopBase &&
												600,
											borderRadius: 6,
											zIndex: 9999999999999999999999,
											objectFit: `${
												isStreamDeviceDesktop ||
												(!isStreamDeviceDesktop &&
													recordingLink)
													? 'contain'
													: 'cover'
											}`,
											// transform: 'rotateY(180deg)',
										}}
										className={
											isLessThanDesktopBase
												? 'video-mobile'
												: 'video'
										}
									/>
								) : (
									<video
										playsInline
										id="video-player"
										muted={false}
										ref={consumerVideoEl}
										style={{
											width: '100%',
											borderRadius: 6,
											objectFit: `${
												isStreamDeviceDesktop
													? 'contain'
													: 'cover'
											}`,
											// transform: 'rotateY(180deg)',
										}}
										className={
											isLessThanDesktopBase
												? 'video-mobile'
												: 'video'
										}
									/>
								)}
							</>
						)}

					{(isLoadingCameraOrDailyOrIvs && typeOfAccount) ||
					isLoadingRecording ? (
						<View
							style={LiveEventSectionStyles.tilesLoaderContainer}
						>
							{!isLessThanDesktopBase && (
								<Loader color={palettes.light[0]} />
							)}
						</View>
					) : (
						isAccountHostOrSeller(typeOfAccount) && (
							<LiveEventTiles
								callItems={callItems}
								onVideoHeight={val => setVideoHeight(val)}
								eventDetails={eventDetails}
							/>
						)
					)}

					{(showLiveStreamProfileCard ||
						isHostOrSellerInEvent ||
						!typeOfAccount) && (
						<HostDetails
							isLiveStreaming={isLiveStreaming}
							username={profileCardDetails.username}
							personalInformation={
								profileCardDetails.personalInformation
							}
						/>
					)}
					{(showLiveEventStats || isHostOrSellerInEvent) &&
						!isLessThanDesktopBase && (
							<LiveEventStats
								totalUsersInRoom={liveEventAudience.length}
								totalInvitedPeople={
									eventDetails.invitedPeople.length
								}
								isHostRequired={eventDetails.isHostRequired}
								isLiveStreaming={isLiveStreaming}
								typeOfAccount={typeOfAccount}
								liveStreamStartedAtTime={
									eventDetails?.eventStreamTimeline?.startedAt
								}
							/>
						)}
					{!isLoadingCameraOrDailyOrIvs && !isLessThanDesktopBase && (
						<View
							style={
								LiveEventSectionStyles.liveStreamActionButtons
							}
						>
							{showActionButtonsForConsumer ||
							(isHostOrSellerInEvent &&
								typeOfAccount === 'CONSUMER') ? (
								<ConsumerActionButtons />
							) : (
								showActionButtonsForHostOrSeller && (
									<SellerAndHostActionButtons />
								)
							)}
						</View>
					)}
				</View>

				{!isLessThanDesktopBase && (
					<View style={{ position: 'relative' }}>
						<LiveEventLock
							lockColor={'red'}
							showLocks={showLocks}
							lockIconDimensions={
								LOCK_DIMENSIONS.descriptionArea.icon
							}
							lockBackgroundDimension={
								LOCK_DIMENSIONS.descriptionArea.background
							}
							item={eventDetails}
						/>
						<LiveEventProfileCard
							userDescription={userDescription}
							userProfileImageLink={userProfileImageLink}
							username={username}
							userId={userId}
							eventDetails={eventDetails}
							// loadingRecording={isLoadingRecording}
							// handleRecord={handleRecord}
							// isStreamEnded={!!isStreamEnded?.length}
						/>
						<HorizontalDivider />
						<AboutEvent about={about} />
					</View>
				)}

				{isLessThanDesktopBase && (
					<View
						style={[
							LiveEventSectionStyles.eventContent,
							selectedSectionTab !== 'Live' && {
								backgroundColor: palettes.light[0],
							},
						]}
					>
						<View style={LiveEventSectionStyles.eventHeader}>
							{/* (showLiveEventStats || isHostOrSellerInEvent) */}
							{true && (
								<StreamHeader
									totalUsersInRoom={liveEventAudience.length}
									totalInvitedPeople={
										eventDetails?.invitedPeople?.length
									}
									isHostRequired={
										eventDetails?.isHostRequired
									}
									isLiveStreaming={isLiveStreaming}
									typeOfAccount={typeOfAccount}
									liveStreamStartedAtTime={
										eventDetails?.eventStreamTimeline
											?.startedAt
									}
									selectedTab={selectedSectionTab}
								/>
							)}
							{selectedSectionTab !== 'Live' && (
								// <StreamHeaderTab
								// 	onPress={onPress}
								// 	selectedTab={selectedSectionTab}
								// 	tabs={getTabs('section')}
								// />
								<LiveStreamActionWrapper
									icon={
										<BackEditIcon
											width="25"
											height="25"
											fill="#000"
											containerStyles={{
												transform: [
													{ rotate: '90deg' },
												],
											}}
											onPress={() => onPress('Live')}
										/>
									}
									onPress={() => onPress('Live')}
								/>
							)}
						</View>
						<View
							style={[
								LiveEventSectionStyles.eventProductDiscussion,
								selectedSectionTab === 'Live' && {
									justifyContent: 'flex-end',
								},
							]}
						>
							{isLessThanDesktopBase &&
								recordingLink &&
								isStreamEnded &&
								currentVideoTime < 1 && (
									<View
										style={{
											position: 'absolute',
											top: '40%',
											left: '45%',
											zIndex: 9999999999999,
										}}
									>
										<RecordingPlayButton />
										{/* <Loader color={palettes.light[0]} /> */}
									</View>
								)}
							{isLessThanDesktopBase &&
								selectedSectionTab === 'Live' && (
									<StartLiveStreamButton size="sm" />
								)}
							{/* {!isLoadingCameraOrDailyOrIvs &&
								isLessThanDesktopBase &&
								selectedSectionTab === 'Live' && (
									<View
										style={[
											LiveEventSectionStyles.liveStreamActionButtons,
											{
												bottom: getResponsiveStyle(250),
												zIndex: 99999,
											},
										]}
									>
										{showActionButtonsForConsumer ||
											(isHostOrSellerInEvent &&
												typeOfAccount === 'CONSUMER') ? (
											<ConsumerActionButtons />
										) : (
											showActionButtonsForHostOrSeller && (
												<SellerAndHostActionButtons />
											)
										)}
									</View>
								)} */}
							{/* {!isUserLoggedIn && (
								<View
									style={[
										LiveEventSectionStyles.liveStreamActionButtons,
										{
											bottom: getResponsiveStyle(250),
											zIndex: 99999,
										},
									]}
								>
									<Button
										title={`+${t('Subscribe')}`}
										variant="primary"
										onPress={handleSubToEvent}
									/>
								</View>
							)} */}
							{selectedSectionTab === 'Live' && (
								<LiveEventLiveTab
									messages={messages}
									products={products}
									eventDetails={eventDetails}
									totalVideoTime={totalVideoTime}
									highlightedEventProductsTime={
										highlightedEventProductsTime
									}
									sleekToTime={sleekToTime}
									handlePauseAndPlay={handlePauseAndPlay}
									currentVideoTime={currentVideoTime}
									playVideo={playVideo}
									// handleTypingFocusIn={handleTypingFocusIn}
									handleChange={handleChange}
									// isLiveStreaming={isLiveStreaming}
								/>
							)}

							{selectedSectionTab === 'Live' && (
								<EventNavigation
									eventData={eventDetails}
									onPressMute={
										typeOfAccount === 'SELLER'
											? onPressMicSellerOrHost
											: onPressVolumeConsumer
									}
									isVolumeDisabledForConsumer={
										isVolumeDisabledForConsumer
									}
									onPressTab={onPress}
									isAudioEnabledForHostSeller={
										!isAudioEnabledForHostSeller(
											dailyJsObject
										)
									}
									isVideoEnabledForHostSeller={
										!isVideoEnabledForHostSeller(
											dailyJsObject
										)
									}
									onPressVideoSellerOrHost={
										onPressVideoSellerOrHost
									}
									onPressHangButton={
										typeOfAccount === 'SELLER'
											? () => setShowEndCallModal(true)
											: onPressHangBtnConsumer
									}
									isLiveStreaming={isLiveStreaming}
									typeOfAccount={typeOfAccount}
								/>
							)}
							{selectedSectionTab === 'Products' && (
								<LiveEventProducts
									products={products}
									eventDetails={eventDetails}
								/>
							)}
							{selectedSectionTab === 'Participants' && (
								<LiveEventParticipants
									handleParticipantsOptions={
										handleParticipantsOptions
									}
									selectedParticipantTab={
										selectedParticipantTab
									}
									liveEventAudience={liveEventAudience}
									messages={messages}
								/>
							)}
							{selectedSectionTab === 'About' && (
								<AboutEvent about={about} />
							)}
						</View>
						{/* <Separator /> */}
					</View>
				)}
			</ScrollView>
		</View>
	);
};

export default LiveEventSection;
